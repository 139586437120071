import { useState } from "react";
import { useHistory, withRouter, Link } from "react-router-dom";
import Navbar2 from "./navbar2";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Loader from "./common/loader";
import Bottomnav from "./bottomnav";
import Navbar from "./navbar.js";
import Footer from "./footer.js";
import axios from 'axios'


function Forgetpass() {
  let history = useHistory();
  const [phone, setPhone] = useState("");
  const [activeTab, setActivetab] = useState("phone");
  const [vericode, setVericode] = useState("");
  const [cvericode, setCvericode] = useState("");

  const [password, setPassword] = useState("");
  const [rpassword, setRpassword] = useState("");
  const [load, setLoad] = useState(false);

  function sendOtp(msg) {
            var num = '88'+phone;
            axios.post("https://sowdaapp.com/sendotp.php",{
              num : num,
              msg : msg
            }) 
            .then(res=> console.log('') )
            .catch(err=> console.log(err))
  }

  function submitHandle() {
    if (phone == "") {
      toast.dark("Phone is empty", {
        icon: "📧",
      });

      return false;
    }

    setLoad(true);
    const data = new FormData();
    data.append("phone", phone);

    fetch("https://sowdaapp.com/sandweep/phonecheck/", {
      method: "POST",
      body: data,
    })
      .then((res) => res.json())
      .then((res) => {

        
        setLoad(false);
        if (res.message == true) {
          setActivetab("verification");
          var val = Math.floor(1000 + Math.random() * 9000);
          setVericode(val);
          var msg = "আপনার সন্দ্বীপ মল ভেরিফিকেশন কোডটি হচ্ছে " + val;
          sendOtp(msg);
        } else {
          toast.dark(res.message, {
            icon: "⚠️",
          });
        }
      })
      .catch((err) => console.log(err));
  }

  function handleVerify() {
    if (vericode == cvericode) {
      setActivetab("cpass");
    } else {
      toast.dark("Invalid verification code", {
        icon: "⚠️",
      });
    }
  }

  function passHandle() {
    if (password != rpassword) {
      toast.dark("Password do not match", {
        icon: "⚠️",
      });
      return false;
    }

    const data = new FormData();
    data.append("password", password);
    data.append("phone", phone);

    fetch("https://sowdaapp.com/sandweep/uppassOtp", {
      method: "POST",
      body: data,
    })
      .then((res) => res.json())
      .then((res) => {
        console.log(res);
        console.log(res)
        if (res.message == null) {
          localStorage.setItem("token", res.token);
          history.push("/account");
        }
      })
      .catch((err) => console.log(err));
  }

  return (
    <>
      <Loader load={load} />
      <Navbar />
      <Navbar2 />
      <Bottomnav />
      <ToastContainer />
      <div class="container-fluid my-5 ">
        <div class="row px-0 mx-0">
          <center class="my-lg-5 my-3">
            <div class="col-lg-4 col-12">
              <div class="card rounded-0 py-3">
                {activeTab == "phone" ? (
                  <div class="card-body px-lg-5 px-3">
                    <small class="text-muted">আপনার মোবাইল নাম্বার লিখুন</small>

                    <div class="col-lg-12 my-2">
                      <input
                        onChange={(e) => setPhone(e.target.value)}
                        type="phone"
                        class="form-control log-f-border"
                        placeholder="01XXXXXXXXX"
                        aria-label="Your email"
                      />
                    </div>

                    <button
                      onClick={submitHandle}
                      class="btn btn-success text-center w-100 fw-bold log-btn"
                    >
                      পরবর্তী ধাপ
                    </button>
                  </div>
                ) : null}

                {activeTab == "verification" ? (
                  <div class="card-body px-lg-5 px-3">
                    <p class="text-muted">
                      আপনার মোবাইল নাম্বারে একটি ভেরিফিকেশন কোড পাঠানো হয়েছে
                    </p>

                    <div class="col-lg-12 my-2">
                      <input
                        onChange={(e) => setCvericode(e.target.value)}
                        type="text"
                        class="form-control log-f-border"
                        placeholder="* * * *"
                        aria-label="Your email"
                      />
                    </div>

                    <button
                      onClick={handleVerify}
                      class="btn btn-success text-center w-100 fw-bold log-btn"
                    >
                      ভেরিফাই করুন
                    </button>
                  </div>
                ) : null}

                {activeTab == "cpass" ? (
                  <div class="card-body px-lg-5 px-3">
                    <p class="text-muted">পাসওয়ার্ড পরিবর্তন করুন </p>

                    <div class="col-lg-12 my-2">
                      <input
                        onChange={(e) => setPassword(e.target.value)}
                        type="password"
                        class="form-control log-f-border"
                        placeholder="নতুন পাসওয়ার্ড দিন"
                        aria-label="Your email"
                      />
                    </div>

                    <div class="col-lg-12 my-2">
                      <input
                        onChange={(e) => setRpassword(e.target.value)}
                        type="password"
                        class="form-control log-f-border"
                        placeholder="পুনরায় লিখুন "
                        aria-label="Your email"
                      />
                    </div>

                    <button
                      onClick={passHandle}
                      class="btn btn-success text-center w-100 fw-bold log-btn"
                    >
                      পরিবর্তন করুন
                    </button>
                  </div>
                ) : null}
              </div>
            </div>
          </center>
        </div>
      </div>
      <Footer />
    </>
  );
}

export default withRouter(Forgetpass);
